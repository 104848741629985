export const security = [
  '14 16',
  `      <path
        d="M12.4,1.63,12,1.55c-.49-.1-1-.2-1.49-.33a17.63,17.63,0,0,1-2.78-1,1.78,1.78,0,0,0-1.53,0A17.38,17.38,0,0,1,1.49,1.63,1.68,1.68,0,0,0,0,3.38V8.56A4,4,0,0,0,.26,10a6.78,6.78,0,0,0,1.64,2.4A17.71,17.71,0,0,0,6.18,15.4a1.47,1.47,0,0,0,.73.19,1.67,1.67,0,0,0,.79-.21,21,21,0,0,0,2.79-1.78A10.51,10.51,0,0,0,13,11.08a4.36,4.36,0,0,0,.84-2.46c0-1.76,0-3.52,0-5.28A1.74,1.74,0,0,0,12.4,1.63Zm.29,1.71q0,2.64,0,5.28A3.29,3.29,0,0,1,12,10.45,9.29,9.29,0,0,1,9.8,12.7a18.88,18.88,0,0,1-2.63,1.68.45.45,0,0,1-.46,0,16.42,16.42,0,0,1-4-2.86,5.39,5.39,0,0,1-1.39-2,2.71,2.71,0,0,1-.19-1V3.38a.57.57,0,0,1,.52-.63,18.36,18.36,0,0,0,5-1.55.7.7,0,0,1,.6,0,18.65,18.65,0,0,0,3,1.11c.52.14,1.05.24,1.56.34l.44.09A.59.59,0,0,1,12.69,3.34Z"
        style="fill:currentColor"/>
      <path
        d="M10.63,4.76a.53.53,0,0,0-.58.16c-.77.78-1.52,1.57-2.3,2.33-.62.62-1.27,1.23-1.91,1.84l0,.06L5.74,9,4.22,7.49a.56.56,0,1,0-.79.79C4,8.89,4.64,9.5,5.25,10.1a.89.89,0,0,0,.37.23.83.83,0,0,0,.92-.25C7.38,9.24,8.21,8.39,9,7.55l1.79-1.81A.61.61,0,0,0,11,5.2.52.52,0,0,0,10.63,4.76Z"
        style="fill:currentColor"/>
`
];

export const dashboard = [
  '20 20',
  `            <path
        d="M4.89,7.81a24.19,24.19,0,0,0-3.51,0A1.5,1.5,0,0,0,0,9.29a28.21,28.21,0,0,0,0,3.05,1.66,1.66,0,0,0,1.58,1.58c.49,0,1,0,1.49,0h0c.47,0,.95,0,1.42,0a1.56,1.56,0,0,0,1.61-1.44,25.27,25.27,0,0,0,0-3.37A1.32,1.32,0,0,0,4.89,7.81ZM3,12.84c-1.91,0-1.91,0-1.92-1.93V10.5c0-1.59.06-1.64,1.69-1.71C5,8.7,5.17,8.86,5.14,11.05,5.13,12.76,5,12.85,3,12.84Z"
        style="fill:currentColor"/>
      <path
        d="M4.31.07,3.11,0,1.67.08A1.66,1.66,0,0,0,0,1.67c0,1,0,2,0,3.05a1.55,1.55,0,0,0,1.48,1.5,25.7,25.7,0,0,0,3,0A1.57,1.57,0,0,0,6.19,4.66a17.08,17.08,0,0,0,0-2.77C6.11.61,5.58.15,4.31.07ZM3.09,5.18c-1.92,0-2,0-2-2s0-2.06,2.07-2C5,1.3,5.1.8,5.14,3.15,5.17,5.1,5.06,5.19,3.09,5.18Z"
        style="fill:currentColor"/>
      <path
        d="M9.13,6.21a21.05,21.05,0,0,0,2.38.1c1.89-.08,2.3-.51,2.4-2.42,0-.23,0-.45,0-.71,0-.51,0-1.06-.1-1.6A1.56,1.56,0,0,0,12.35.1c-1,0-2.09,0-3.13,0A1.49,1.49,0,0,0,7.78,1.4a27.56,27.56,0,0,0,0,3.52A1.38,1.38,0,0,0,9.13,6.21Zm1.67-5c2,0,2,0,2,2s0,2-2,2-2-.07-2-2S8.82,1.15,10.8,1.16Z"
        style="fill:currentColor"/>
      <path
        d="M13.86,9.28a1.49,1.49,0,0,0-1.21-1.45,26.88,26.88,0,0,0-3.7,0A1.3,1.3,0,0,0,7.76,9.13a28.12,28.12,0,0,0,0,3.31,1.54,1.54,0,0,0,1.56,1.48c1,.05,1.95.05,2.92,0a1.69,1.69,0,0,0,1.63-1.6c.05-.5.06-1,.09-1.47,0-.27,0-.52,0-.77S13.89,9.55,13.86,9.28Zm-3.15,3.56c-1.87,0-1.93-.08-1.93-1.95,0-2.08.1-2.17,2.2-2.1,1.76.06,1.78.08,1.8,2S12.79,12.85,10.71,12.84Z"
        style="fill:currentColor"/>
`
];

export const statistics = [
  '20 20',
  `            <path
  d="M16.2,14.66h-.32V7.76a.5.5,0,0,0-.5-.5h-4V.5a.5.5,0,0,0-.5-.5H6a.5.5,0,0,0-.5.5V3.8a.43.43,0,0,0,0,.05V5.27H1.18a.5.5,0,0,0-.5.5v8.89H.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5.51.51,0,0,0-.5-.5H6.46V5.82s0,0,0-.05V1h3.87V15.16a.5.5,0,0,0,.5.5H16.2a.5.5,0,0,0,.5-.5A.51.51,0,0,0,16.2,14.66Zm-14.52,0V6.27H5.46v8.39Zm9.66-6.4h3.54v6.4H11.34Z"
style="fill:currentColor"/>
<path d="M8.88,14.66H8.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h.38a.5.5,0,0,0,.5-.5A.51.51,0,0,0,8.88,14.66Z"
style="fill:currentColor"/>
`
];
export const messaging = [
  '20 20',
  `                  <path
        d="M5.51,9.61h6.61a1.66,1.66,0,0,0,1.8-1.79c0-.6,0-1.2,0-1.8,0-1.36,0-2.76,0-4.14A1.89,1.89,0,0,0,13.41.51,1.83,1.83,0,0,0,12.09,0H12c-1.1,0-2.21,0-3.29,0h-7A1.66,1.66,0,0,0,0,1.68C0,3.67,0,5.73,0,8A1.61,1.61,0,0,0,1.28,9.55a4.34,4.34,0,0,0,.52.07l.22,0v1.6a.78.78,0,0,0,.5.85.67.67,0,0,0,.24,0,.87.87,0,0,0,.63-.37l.41-.47c.43-.49.86-1,1.28-1.49a.49.49,0,0,1,.43-.19Zm-.37-1a.73.73,0,0,0-.62.29c-.28.34-.57.66-.85,1l-.48.56-.14.18H3v-1c0-.15,0-.3,0-.45a.5.5,0,0,0-.52-.52H1.68A.62.62,0,0,1,1,8V1.72a.6.6,0,0,1,.63-.65H12.27a.6.6,0,0,1,.63.64V7.94a.63.63,0,0,1-.72.66h-7Z"
        style="fill:currentColor"/>
      <path
        d="M17.22,4.17a1.65,1.65,0,0,0-1.55-1.56h0a.52.52,0,0,0-.56.45c0,.31.14.52.49.58a.65.65,0,0,1,.64.73v6.09a.65.65,0,0,1-.7.73h-.69c-.46,0-.62.17-.62.63s0,.68,0,1v.33L14.05,13l-.33-.39c-.33-.37-.66-.75-1-1.14a.86.86,0,0,0-.74-.34H6a1.23,1.23,0,0,0-.38,0,.49.49,0,0,0,.07,1,1.62,1.62,0,0,0,.31,0h5.63a.61.61,0,0,1,.52.25l1.57,1.82.12.13a.82.82,0,0,0,.62.33.85.85,0,0,0,.26,0,.75.75,0,0,0,.45-.77V12.21h.34a1.66,1.66,0,0,0,1.67-1.57C17.24,8.55,17.24,6.37,17.22,4.17Z"
        style="fill:currentColor"/>
      <path
        d="M7,4.31A.66.66,0,0,0,6.29,5a.63.63,0,0,0,.19.47A.65.65,0,0,0,7,5.63.67.67,0,0,0,7.63,5a.62.62,0,0,0-.19-.47A.66.66,0,0,0,7,4.31Z"
        style="fill:currentColor"/>
      <path
        d="M8.92,5.63h0A.64.64,0,0,0,9.59,5a.65.65,0,0,0-.67-.67h0a.64.64,0,0,0-.64.63.66.66,0,0,0,.18.49A.63.63,0,0,0,8.92,5.63Z"
        style="fill:currentColor"/>
      <path
        d="M5,4.31H5a.64.64,0,0,0-.65.63.71.71,0,0,0,.18.49.67.67,0,0,0,.47.2H5A.65.65,0,0,0,5.65,5a.63.63,0,0,0-.19-.48A.64.64,0,0,0,5,4.31Z"
        style="fill:currentColor"/>
`
];
export const history = [
  '20 20',
  `                        <path
        d="M1.1,8.53H2.37A1.11,1.11,0,0,0,3.46,7.46c0-.44,0-.87,0-1.3A1.1,1.1,0,0,0,2.38,5.08c-.43,0-.85,0-1.27,0A1.12,1.12,0,0,0,0,6.18c0,.2,0,.4,0,.6s0,.43,0,.64A1.12,1.12,0,0,0,1.1,8.53ZM.77,6.26a.39.39,0,0,1,.43-.43H2.29a.39.39,0,0,1,.42.41c0,.37,0,.75,0,1.12a.4.4,0,0,1-.42.42H1.72c-1,0-1,0-1-.94C.76,6.65.76,6.45.77,6.26Z"
        style="fill:currentColor"/>
      <path
        d="M1.09,3.46c.44,0,.87,0,1.3,0A1.1,1.1,0,0,0,3.46,2.39q0-.66,0-1.32A1.13,1.13,0,0,0,2.38,0H1.12A1.15,1.15,0,0,0,0,1.1C0,1.53,0,2,0,2.37A1.12,1.12,0,0,0,1.09,3.46ZM1.72.76c.19,0,.37,0,.55,0s.44.15.44.46,0,.68,0,1-.14.46-.46.47-.34,0-.51,0c-1,0-1,0-1-1S.76.76,1.72.76Z"
        style="fill:currentColor"/>
      <path
        d="M2.38,10.15c-.43,0-.87,0-1.3,0A1.11,1.11,0,0,0,0,11.22q0,.64,0,1.29A1.13,1.13,0,0,0,1.07,13.6c.44,0,.89,0,1.33,0a1.12,1.12,0,0,0,1.06-1.08c0-.22,0-.44,0-.66s0-.42,0-.63A1.12,1.12,0,0,0,2.38,10.15Zm-.64,2.7c-1,0-1,0-1-1s0-1,1-1h.52c.33,0,.47.14.48.48v.48C2.72,12.85,2.72,12.85,1.74,12.85Z"
        style="fill:currentColor"/>
      <path
        d="M13.28,11.51c-.06,0-.18,0-.27,0H5.4a1.22,1.22,0,0,0-.27,0,.33.33,0,0,0-.33.36.36.36,0,0,0,.33.37H13a1.22,1.22,0,0,0,.27,0c.2,0,.35-.19.3-.38S13.4,11.6,13.28,11.51Z"
        style="fill:currentColor"/>
      <path
        d="M5.12,2.09c.07.05.2,0,.3,0h7.85a.34.34,0,0,0,.33-.37.35.35,0,0,0-.33-.37,1.92,1.92,0,0,0-.24,0H5.38a1.22,1.22,0,0,0-.27,0c-.2,0-.35.18-.29.38S5,2,5.12,2.09Z"
        style="fill:currentColor"/>
      <path
        d="M13.3,6.45c-.07,0-.2,0-.3,0H5.39c-.1,0-.23,0-.3,0s-.27.22-.27.33.16.27.27.38.15,0,.23,0h7.77c.07,0,.16,0,.2,0s.29-.23.29-.36S13.42,6.54,13.3,6.45Z"
        style="fill:currentColor"/>
`
];

export const address_book = [
  '20 20',
  `      <path
        d="M14.1,0h-12A1.19,1.19,0,0,0,.8,1.26V3.15H.51a.49.49,0,1,0,0,1H.79V7.19H.53A.5.5,0,0,0,0,7.67a.48.48,0,0,0,.52.5H.79v3.07H.5a.48.48,0,0,0-.5.48.48.48,0,0,0,.49.49H.8v1.88a1.2,1.2,0,0,0,1.25,1.27H14.1a1.19,1.19,0,0,0,1.25-1.26V1.26A1.19,1.19,0,0,0,14.1,0ZM2.91,14.37H2.7a6.94,6.94,0,0,1-.79,0c-.09,0-.13-.09-.13-.17v-2h.28a.48.48,0,0,0,.51-.49A.49.49,0,0,0,2,11.24H1.8V8.17h.27a.5.5,0,0,0,.51-.5.48.48,0,0,0-.5-.48H1.78V4.12h.28a.49.49,0,1,0,0-1H1.78V1.27c0-.23.07-.3.3-.3h.84ZM14.38,14c0,.31-.05.37-.34.37H3.9V1H14c.31,0,.36.06.36.37Z"
        style="fill:currentColor"/>
      <path
        d="M11.16,7.34l-.09-.06a2.59,2.59,0,0,0,.58-1.79,2.6,2.6,0,0,0-.88-1.75A2.51,2.51,0,0,0,7.23,7.28,3.71,3.71,0,0,0,5.59,11.5a.91.91,0,0,0,.93.71h5.25a.9.9,0,0,0,.94-.73A3.61,3.61,0,0,0,11.6,7.69,3.75,3.75,0,0,0,11.16,7.34Zm-2-3.22h0a1.54,1.54,0,0,1,1.09.45,1.55,1.55,0,0,1,.45,1.09,1.54,1.54,0,0,1-3.07,0A1.55,1.55,0,0,1,9.14,4.12Zm2.65,7c0,.09,0,.11-.15.11h0L8,11.21H6.51a2.72,2.72,0,0,1,1.11-3l.13-.08A.6.6,0,0,1,8.38,8,2.11,2.11,0,0,0,10.08,8a.25.25,0,0,1,.22,0A2.72,2.72,0,0,1,11.79,11.13Z"
        style="fill:currentColor"/>
`
];
export const settings = [
  '20 20',
  `      <path
        d="M15.46,7A3.4,3.4,0,0,1,14,3.77c.13-1.4-.19-1.72-1.57-1.59A3.25,3.25,0,0,1,9.24.78l0-.05C8.28-.23,8-.24,7,.68a2.72,2.72,0,0,0-.37.39A2.07,2.07,0,0,1,4.38,2.15h-1c-.87,0-1.34.38-1.23,1.24A3.82,3.82,0,0,1,.52,7.24a.91.91,0,0,0,0,1.64,3.91,3.91,0,0,1,1.66,3.86c-.11.93.32,1.34,1.27,1.23a3.78,3.78,0,0,1,3.7,1.55c.7.89,1.22.89,1.94,0A3.75,3.75,0,0,1,12.72,14c1,.11,1.42-.31,1.3-1.33a3.62,3.62,0,0,1,1.49-3.57C16.44,8.34,16.4,7.78,15.46,7Zm-1.13,1.6,0,.05A3.14,3.14,0,0,0,12.9,12c.11.67-.22.93-.84.83a3.42,3.42,0,0,0-3.41,1.45c-.53.65-.62.61-1.19,0-.21-.23-.45-.45-.65-.7a2,2,0,0,0-1.73-.72c-2,0-1.72.21-1.72-1.72a2.12,2.12,0,0,0-.76-1.8c-.28-.22-.52-.5-.8-.72s-.51-.67-.05-1a3.5,3.5,0,0,0,1.6-3.67c-.06-.41.16-.64.59-.63h.14A3.32,3.32,0,0,0,7.49,1.87c.52-.7.62-.64,1.24,0,.2.21.44.4.62.63a1.89,1.89,0,0,0,1.84.77h.27c1.68,0,1.42-.11,1.44,1.62-.38,1.19.6,1.85,1.41,2.54C14.85,7.91,14.86,8.22,14.33,8.62Z"
        style="fill:currentColor"/>
      <path
        d="M8.24,4.44A3.58,3.58,0,0,0,4.5,8a3.63,3.63,0,0,0,3.55,3.7A3.7,3.7,0,0,0,11.76,8.2,3.72,3.72,0,0,0,8.24,4.44Zm-.11,6.14A2.46,2.46,0,0,1,5.65,8.06,2.46,2.46,0,0,1,8.08,5.57,2.56,2.56,0,0,1,10.6,8.08,2.61,2.61,0,0,1,8.13,10.58Z"
        style="fill:currentColor"/>
`
];
export const web = [
  '20 20',
  `      <path
        d="M7.11,0h0a7.11,7.11,0,0,0,0,14.22A7.12,7.12,0,0,0,14.22,7.1,7.12,7.12,0,0,0,7.11,0Zm4.66,3.45L10.24,4A11.33,11.33,0,0,0,9.11,1.36a6,6,0,0,1,2.81,2ZM10.23,10.2h0l1.53.59.13.05a6.25,6.25,0,0,1-2.79,2A11.68,11.68,0,0,0,10.23,10.2Zm.23-1.07a11.6,11.6,0,0,0,0-4,.11.11,0,0,1,0,0h0c.7-.27,1.33-.53,1.92-.79l.06,0,0,0a6.37,6.37,0,0,1,.71,2.83,6.35,6.35,0,0,1-.71,2.84.16.16,0,0,1,0,.06l-.07,0c-.55-.25-1.17-.5-1.9-.78l0,0ZM4.77,8.91a10.07,10.07,0,0,1,0-3.59,11.63,11.63,0,0,0,4.7,0,10.36,10.36,0,0,1,0,3.6,11.9,11.9,0,0,0-2.37-.23A11.27,11.27,0,0,0,4.77,8.91ZM9.23,4.32l-1.4.17-.72.09-.67-.09L5,4.32H5v0A11.07,11.07,0,0,1,6.45,1.14l0,0A1.06,1.06,0,0,1,7.06,1h.09a1.06,1.06,0,0,1,.61.14A10.32,10.32,0,0,1,9.24,4.28a.06.06,0,0,1,0,0ZM5.1,1.37A11.5,11.5,0,0,0,4,4H4L2.65,3.53,2.32,3.4A5.91,5.91,0,0,1,5.1,1.37ZM3.75,5.09a11.54,11.54,0,0,0,0,4.06v0h0c-.7.27-1.33.53-1.95.8l0,0h0a6.06,6.06,0,0,1,0-5.76h0l.07,0,.51.21L3.7,5l.06,0ZM2.32,10.85l1.43-.59L4,10.18H4v0a11,11,0,0,0,1.08,2.58,5.66,5.66,0,0,1-2.76-1.94ZM5,10s0,0,0-.05H5l1.6-.19.5-.06.79.09,1.3.16h.06l0,.06a10.13,10.13,0,0,1-1.45,3,.44.44,0,0,1-.26.16c-.94.12-.95.11-1.45-.71A10.91,10.91,0,0,1,5,10Z"
        style="fill:currentColor"/>
`
];
export const send = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.86 14.86" style="fill: currentColor">
      <path
        d="M10,14.86a2.09,2.09,0,0,1-1.59-.71L6.45,11.94a.6.6,0,0,1,.11-.89.72.72,0,0,1,1,.1l1.92,2.21a.7.7,0,0,0,.68.22.65.65,0,0,0,.52-.47L13.48,2a.62.62,0,0,0-.21-.62.74.74,0,0,0-.69-.12L1.82,4.84a.62.62,0,0,0-.45.51.6.6,0,0,0,.3.6l3.68,2.3L8.19,5.69a.72.72,0,0,1,1,0,.59.59,0,0,1,0,.89L5.91,9.51a.75.75,0,0,1-.86.07L.9,7A1.83,1.83,0,0,1,0,5.19,1.9,1.9,0,0,1,1.36,3.65L12.13.11a2.18,2.18,0,0,1,2,.37,1.81,1.81,0,0,1,.63,1.85L12,13.4a2,2,0,0,1-1.54,1.41A2.31,2.31,0,0,1,10,14.86Z"
        />
</svg>
`
];

export const visible = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.81 9.65" style="fill: currentColor">
  <g id="Calque_2" data-name="Calque 2">
    <g id="Calque_1-2" data-name="Calque 1">
      <path
        d="M16.47,4l-.24-.29A9.89,9.89,0,0,0,7.53,0,9.84,9.84,0,0,0,.31,4a1.16,1.16,0,0,0,0,1.57,11.5,11.5,0,0,0,.87,1A10.09,10.09,0,0,0,8.65,9.65c.44-.06,1.26-.12,2-.29a9.94,9.94,0,0,0,5.8-3.75A1.21,1.21,0,0,0,16.47,4ZM13.94,6.59A9,9,0,0,1,1.49,5.23c-.42-.51-.41-.36,0-.83a8.71,8.71,0,0,1,8-3.18A8.59,8.59,0,0,1,15.58,4.7l.08.11A7.66,7.66,0,0,1,13.94,6.59Z"
        />
      <path
        d="M8.4,1.47a3.34,3.34,0,0,0,0,6.68,3.28,3.28,0,0,0,3.31-3.36A3.28,3.28,0,0,0,8.4,1.47ZM8.41,7a2.2,2.2,0,0,1,0-4.4,2.16,2.16,0,0,1,2.15,2.22A2.15,2.15,0,0,1,8.41,7Z"
        />
    </g>
  </g>
</svg>
`
];

export const invisible = [
  '16.81 16.54',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.81 16.54" style="fill: currentColor">
  <g id="Calque_2" data-name="Calque 2">
    <g id="Calque_1-2" data-name="Calque 1">
      <path
        d="M16.47,7.4l-.24-.28A10.79,10.79,0,0,0,13,4.49l3.19-3.44a.63.63,0,0,0,0-.89.63.63,0,0,0-.88,0L11.73,4a10.35,10.35,0,0,0-4.2-.51,9.84,9.84,0,0,0-7.22,4A1.16,1.16,0,0,0,.31,9a10.27,10.27,0,0,0,.87,1,10,10,0,0,0,3,2.12L1.05,15.5a.63.63,0,0,0,0,.88.61.61,0,0,0,.42.16.59.59,0,0,0,.46-.2l3.45-3.72a12.45,12.45,0,0,0,3.23.47c.44-.06,1.26-.12,2-.29a9.94,9.94,0,0,0,5.8-3.75A1.23,1.23,0,0,0,16.47,7.4Zm-15,1.27c-.42-.51-.41-.36,0-.83a8.72,8.72,0,0,1,8-3.19,9.77,9.77,0,0,1,1.37.27L5,11.25A8.81,8.81,0,0,1,1.49,8.67Zm9.07-.41a2.15,2.15,0,0,1-2.15,2.18,2,2,0,0,1-.81-.18l2.73-2.94A2.28,2.28,0,0,1,10.56,8.26ZM13.94,10A9,9,0,0,1,6.3,11.67l.49-.54a3,3,0,0,0,1.58.46,3.34,3.34,0,0,0,2.78-5.15l1-1.08a8.9,8.9,0,0,1,3.44,2.78l.08.11A7.61,7.61,0,0,1,13.94,10Z"
        />
      <path
        d="M8.4,4.91a3.32,3.32,0,0,0-2.81,5L6.4,9a2.35,2.35,0,0,1-.14-.79A2.19,2.19,0,0,1,8.41,6.05a2,2,0,0,1,.65.11l.82-.89A3.37,3.37,0,0,0,8.4,4.91Z"
        />
    </g>
  </g>
</svg>
`
];

export const exporti = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.03 14.04" style="fill: currentColor">

      <path
        d="M12.38,7.8A.52.52,0,0,0,12,7.29a.61.61,0,0,0-.5.1.63.63,0,0,0-.22.48v4.18a.78.78,0,0,1-.82.84H2a.77.77,0,0,1-.83-.83c0-2.81,0-5.63,0-8.44A.77.77,0,0,1,2,2.79h4.2a.55.55,0,0,0,.55-.36.63.63,0,0,0-.06-.54.63.63,0,0,0-.51-.24H5.37c-1.14,0-2.28,0-3.42,0A1.89,1.89,0,0,0,0,3.61v8.46a1.77,1.77,0,0,0,.06.49A1.91,1.91,0,0,0,2,14h8.41a2.32,2.32,0,0,0,.57-.06,1.91,1.91,0,0,0,1.45-1.91V8.18C12.39,8.05,12.39,7.92,12.38,7.8Z"
        />
      <path
        d="M14,.93A.93.93,0,0,0,13.11,0H10.28a.54.54,0,0,0-.56.56.53.53,0,0,0,.55.58h1.81L6.44,6.78l0,0-.12.13a.55.55,0,0,0,0,.66.6.6,0,0,0,.48.27.45.45,0,0,0,.16,0,1.12,1.12,0,0,0,.31-.22L12.89,2v1.8a.54.54,0,0,0,.57.57h0A.54.54,0,0,0,14,3.75C14,2.81,14,1.87,14,.93Z"
        />
</svg>
`
];

export const restart = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 12.07" style="fill: currentColor">
      <path
        d="M5.48,12.07A5.47,5.47,0,0,1,.1,5.64c.07-.36.12-.8.6-.74s.45.51.4.9A4.39,4.39,0,0,0,4.62,11,4.47,4.47,0,0,0,9.79,5.26a4.54,4.54,0,0,0-4.5-3.18c-.71,0-.78.18-.52.84a1,1,0,0,1,0,.84c-.14.16-.56.14-.84.08a19.47,19.47,0,0,1-2-.51c-.56-.16-.65-.42-.42-.94s.59-1.31.92-1.94A.88.88,0,0,1,3.07,0,.8.8,0,0,1,3.6.45c.24.65.59.77,1.31.67a5.49,5.49,0,1,1,.57,11Z"
        />
</svg>
`
];

export const arrow = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.78 8.16" style="fill: currentColor">
      <path
        d="M.65,8.16A.63.63,0,0,1,.19,8a.66.66,0,0,1,0-.92l3-3L.28,1.11a.64.64,0,0,1,0-.92.66.66,0,0,1,.92,0L4.58,3.58a.63.63,0,0,1,0,.92L1.11,8A.63.63,0,0,1,.65,8.16Z"
        />
</svg>
`
];

export const trash = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.48 15.13" style="fill: currentColor">
      <path
        d="M10,3.94a.92.92,0,0,0-.63-.2q-3.65,0-7.3,0a.8.8,0,0,0-.9.83,33.36,33.36,0,0,0-.06,8.53,2.12,2.12,0,0,0,.61,1.25,2.31,2.31,0,0,0,1.2.59,24.68,24.68,0,0,0,5.68,0,2,2,0,0,0,1.14-.48,2,2,0,0,0,.63-1.41c.11-1.14.19-2.39.19-3.73a37.45,37.45,0,0,0-.3-4.74A.91.91,0,0,0,10,3.94Zm-.74,8.75a1.1,1.1,0,0,1-.22.75,1.3,1.3,0,0,1-.82.41,15.53,15.53,0,0,1-5.09,0,1,1,0,0,1-.9-.91,29,29,0,0,1,0-7.62c0-.42.34-.46.67-.46H5.71c.91,0,1.82,0,2.73,0A.91.91,0,0,1,9.09,5a.84.84,0,0,1,.19.64A26.12,26.12,0,0,1,9.52,9.2,25.5,25.5,0,0,1,9.28,12.69Z"
        />
      <path
        d="M10.78,1.16a18.94,18.94,0,0,0-2.26,0c-.39,0-.69-.06-.74-.54S7.46,0,7,0C6.16,0,5.27,0,4.39,0a.91.91,0,0,0-.67.54c-.1.51-.37.58-.8.57-.73,0-1.46,0-2.19,0-.39,0-.74.09-.73.57s.37.54.75.55h10c.39,0,.77-.15.68-.58A.89.89,0,0,0,10.78,1.16Z"
        />
      <path
        d="M7.34,7.1a.55.55,0,0,0-.48-.58.57.57,0,0,0-.6.58l.06,4.69a.54.54,0,0,0,.5.62.59.59,0,0,0,.55-.57c0-.79,0-1.53,0-2.36Z"
        />
      <path
        d="M5,7.1a.55.55,0,0,0-.48-.58.57.57,0,0,0-.6.58L4,11.79a.54.54,0,0,0,.5.62.59.59,0,0,0,.55-.57c0-.79,0-1.53,0-2.36Z"
        />
</svg>
`
];

export const add = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.45 9.45" style="fill: currentColor">
      <path
        d="M8.8,4.07H5.38V.65a.66.66,0,0,0-1.31,0V4.07H.65a.65.65,0,0,0,0,1.3H4.07V8.8a.66.66,0,0,0,1.31,0V5.37H8.8a.65.65,0,0,0,0-1.3Z"
        />
</svg>
`
];
export const importi = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.42 11.87" style="fill: currentColor">
      <path
        d="M6.59,6C7.38,6.81,8.17,7.6,9,8.38a.45.45,0,0,0,.66,0L12,6a.47.47,0,0,0-.18-.78.45.45,0,0,0-.5.12L9.92,6.75l-.15.14V.45A.47.47,0,0,0,9.3,0a.48.48,0,0,0-.48.44V6.89l-.15-.14L7.27,5.36a.46.46,0,0,0-.67,0A.47.47,0,0,0,6.59,6Z"
        />
      <path
        d="M15.05,4.43c-.43-.06-.56-.28-.64-.68a6.4,6.4,0,0,0-.86-1.91,2.91,2.91,0,0,0-.24-.31.7.7,0,0,0-1-.19.68.68,0,0,0-.12.88l.11.13.1.12a3.7,3.7,0,0,1,.83,1.9c0,.18,0,.35,0,.53,0,.67.29.88,1,.79a2.48,2.48,0,1,1,.37,4.91c-1.2,0-2.41,0-3.62,0H9.75l-5.54-.12a3.47,3.47,0,0,1-3-3.33A3.73,3.73,0,0,1,3.66,3.53a3.46,3.46,0,0,1,2.13-.1.7.7,0,0,0,.7-.61.69.69,0,0,0-.7-.72H5.63A4.66,4.66,0,0,0,3,2.46,5,5,0,0,0,.15,8.19C.63,9.87,2.2,11.6,3.93,11.73l5.78.14H12.1c.82,0,1.64,0,2.46,0a3.76,3.76,0,0,0,3.85-3.4A3.72,3.72,0,0,0,15.05,4.43Z"
        />
</svg>
`
];

export const cross = [
  '20 20',
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.92 7.92" style="fill: currentColor">
      <path
        d="M7.7,6.67,5,4,7.7,1.25a.73.73,0,1,0-1-1L4,2.93,1.25.21a.75.75,0,0,0-1,0,.75.75,0,0,0,0,1L2.93,4,.21,6.67a.75.75,0,0,0,0,1,.75.75,0,0,0,1,0L4,5,6.67,7.7a.73.73,0,1,0,1-1Z"
        />
</svg>

`
];




